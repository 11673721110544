import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import { Title, FieldError, SmallText, Spacer, NormalText } from '../../components/common';
import {
  IonButton,
  IonItem,
  IonInput,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
} from '@ionic/react';
import {
  isDefined,
  checkForDeliveryOption,
  isObject,
  parseAllergenData,
  forwardTo,
  deepCopy,
} from '../../lib/utils';
import moment from '../../lib/moment';
import {
  setCommonModal,
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  setDeliveryOption,
  showToast,
} from '../../store/actions';
import { SET_RESTAURANT_PROP, SHOW_TOAST } from '../../store/constants';
import NoData from '../../components/noData';
import { getMenusForSelectedTime } from '../clickAndCollect';
import './index.css';
import { getConfig } from '../../appConfig';
import api from '../../lib/api';
import { filterMenu } from '../../store/restaurants/sagas';

const { SelectOption } = Mobiscroll;
const restaurantSelectPlaceholder = 'Select location';

class OrderToTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRestaurant:
        props.location.state && props.location.state.selectedRestaurant
          ? props.location.state.selectedRestaurant.restaurant_id
          : null,
      error: '',
      isLocationAllowed: false,
      tableNumber:
        props.location.state && props.location.state.selectedRestaurant
          ? props.location.state.selectedRestaurant.table_name
          : null,
    };
  }
  componentDidMount() {
    const { restaurants, profile } = this.props;
    Basket.setOrderType('table');

    if (this.state.selectedRestaurant) {
      Basket.setRestaurant(
        restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant),
      );
      Basket.setServicePercentage(
        this.props.location.state.selectedRestaurant.service_charge_percentage,
      );
      Basket.setCollectionTime(null);

      this.setState({
        pickTime: moment(Date.now()).format('HH:mm'),
      });
    }
    checkForDeliveryOption(Basket.getDeliveryOption(), '/order-to-table').then((deliveryOption) => {
      if (deliveryOption) {
        this.props.dispatch(getRestaurants());
        this.props.dispatch(setDeliveryOption(deliveryOption));
      }
      if (Basket.getMenu()) {
        this.setState({ selectedIkentooMenu: Basket.getMenu() });
      }

      this.position();
      const enabledRestaurants = restaurants.filter((store) => {
        if (isDefined(store.can_table_order) && isDefined(store.is_published)) {
          if (store.can_table_order && store.is_published) {
            return store;
          }
        }
      });
      if (enabledRestaurants.length === 1) {
        this.initValueOfSelectedRestaurant(enabledRestaurants, profile, deliveryOption);
      }
    });
  }

  componentDidUpdate(prevProps) {
    checkForDeliveryOption(Basket.getDeliveryOption(), '/order-to-table').then(() => {
      if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
        if (this.props.ikentooMenusForLocation[0]) {
          this.setState({
            selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId,
          });
        }
      }
    });
  }

  initValueOfSelectedRestaurant = (restaurants, profile, deliveryOption) => {
    const stores = (restaurants || []).forEach((itm) => {
      if (itm?.id && itm?.name) {
        if (isDefined(itm.can_table_order) && isDefined(itm.is_published)) {
          if (itm.can_table_order && itm.is_published) {
            this.setState(
              { selectedRestaurant: itm.id, pickTime: moment(Date.now()).format('HH:mm') },
              () => {
                Basket.reset(profile?.cardToken);
                Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === itm.id));
                if (getConfig().general.hasServiceCharge) {
                  if (Basket.getRestaurantServiceCharge()) {
                    const service_charge_original = Basket.getRestaurantServiceCharge();
                    Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
                  } else {
                    const defaultServiceCharge = JSON.parse(
                      getConfig().general.defaultServiceCharge,
                    );
                    Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
                  }
                } else {
                  Basket.setServicePercentage(0);
                }
                Basket.setCollectionTime(null);
                Basket.setOrderType('table');
                Basket.setDeliveryOption(deliveryOption);
              },
            );
          }
        }
      }
      return null;
    });

    return stores;
  };

  selectRestaurant = (event, data) => {
    const { restaurants, profile } = this.props;
    const selectedRestaurantId = data.getVal();
    this.setState(
      { selectedRestaurant: selectedRestaurantId, pickTime: moment(Date.now()).format('HH:mm') },
      () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === selectedRestaurantId),
        );
        if (Basket.getRestaurant()) {
          if (getConfig().general.hasServiceCharge) {
            if (Basket.getRestaurantServiceCharge()) {
              const service_charge_original = Basket.getRestaurantServiceCharge();
              Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
            } else {
              const defaultServiceCharge = JSON.parse(getConfig().general.defaultServiceCharge);
              Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
            }
          } else {
            Basket.setServicePercentage(0);
          }
        }
        Basket.setCollectionTime(null);
        Basket.setDeliveryOption(this.props.deliveryOption);
        Basket.setOrderType('table');
      },
    );
  };

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (isDefined(store.can_table_order) && isDefined(store.is_published)) {
        if (store.can_table_order && store.is_published) {
          arrForSelect.push({ text: store.name, value: store.id });
        }
      }
    });

    if (!this.state.isLocationAllowed) {
      arrForSelect.sort(function (a, b) {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });
    }
    if (stores.length > 1) {
      return [{ text: __(restaurantSelectPlaceholder), value: null }, ...arrForSelect];
    } else if (stores.length === 1) {
      return [...arrForSelect];
    }
  };
  getMenuForReorder = (menus, reorderItems) => {
    let menuForReorder = [];
    for (let i = 0; i < menus.length; i++) {
      let foundItems = 0;
      const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
      let items = Basket.flattenMenuItems(deepCopy(categoryItems));
      menus[i].flattenMenuItems = items;
      if (items.length > 0) {
        for (let j = 0; j < reorderItems.length; j++) {
          let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
          if (foundItem) {
            foundItems = foundItems + 1;
          }
        }
      }
      menus[i].foundItems = foundItems;
      menuForReorder.push(menus[i]);
    }
    menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
    if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
      return filterMenu(menuForReorder[0]);
    } else {
      return false;
    }
  };
  chooseMenusForLocation = async () => {
    const { __, restaurants, dispatch } = this.props;
    const { selectedRestaurant, tableNumber, pickTime } = this.state;
    if (selectedRestaurant && tableNumber && pickTime) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      let cutoffTimeRes = moment().unix();
      Basket.setCutoffTime(cutoffTimeRes);
      Basket.setTableNumber(tableNumber);
      Basket.setProcessingFee();
      if (this.props.location.state && this.props.location.state.selectedRestaurant) {
        let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
        ikentooMenusForLocation = getMenusForSelectedTime(
          ikentooMenusForLocation,
          pickTime,
          choosenRestaurant.json_time_selector,
        );
        if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
          let ikentooMenus = [];
          try {
            for (let i = 0; i < ikentooMenusForLocation.length; i++) {
              ikentooMenus.push(
                await api.getIkenooMenu(ikentooMenusForLocation[i].menu_id, businessLocationId),
              );
            }
          } catch (error) {
            this.setState({ error: 'Get restaurant menu error.' });
          }
          const reorderItems = this.props.location.state.selectedRestaurant.items;
          const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
          if (menu) {
            dispatch({
              type: SET_RESTAURANT_PROP,
              key: 'ikentooMenu',
              value: menu,
            });
            if (menu.flattenMenuItems.length > 0) {
              let validationStatus = { notBasketEmpty: false, validationError: false };
              reorderItems.map((newBasketItem, index) => {
                let foundItem = menu.flattenMenuItems.find((i) => i.sku === newBasketItem.item.sku);
                if (foundItem && Basket.isProductEnabled(foundItem)) {
                  if (newBasketItem.selectedChoices[0]) {
                    newBasketItem.selectedChoices[0] = newBasketItem.selectedChoices[0].filter(
                      (el) => Basket.isProductEnabled(el),
                    );
                  }
                  Basket.addToBasket(newBasketItem);
                  let item = newBasketItem.item;
                  let allergens = this.props.allergens;
                  let profile = this.props.profile;
                  let newArr = parseAllergenData(profile, item, allergens);
                  let allergensCodes =
                    newBasketItem.item?.itemRichData?.allergenCodes?.length > 0
                      ? newBasketItem.item.itemRichData.allergenCodes
                      : [];
                  if (allergensCodes.length > 0) {
                    let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                    Basket.setAllergen(allergensData);
                  }
                  validationStatus.notBasketEmpty = true;
                } else {
                  validationStatus.validationError = true;
                }
              });
              if (validationStatus.notBasketEmpty) {
                if (validationStatus.validationError) {
                  dispatch(
                    showToast(
                      'Some items were not added to your basket as they are currently unavailable',
                      'warning',
                    ),
                  );
                }
                forwardTo('/order-summary', { skipBackToThePreviousPage: false });
              } else {
                dispatch(
                  showToast(
                    'Some items were not added to your basket as they are currently unavailable',
                    'warning',
                  ),
                );
              }
            }
          } else {
            dispatch(showToast('Menu not found', 'warning'));
          }
        }
      } else {
        dispatch(
          getIkentooMenusForLocation(businessLocationId, {
            pickTime,
            json_time_selector: choosenRestaurant ? choosenRestaurant.json_time_selector : [],
          }),
        );
      }
    } else if (!selectedRestaurant) {
      this.setState({ error: __('Please select location') });
    } else {
      this.setState({ error: __('Please enter table number') });
    }
  };

  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) =>
        this.setState({
          isLocationAllowed: true,
        }),
      (err) => console.log(err),
    );
  };

  changeIkentooMenus = (event) => {
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });
  };

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      Basket.setMenu(selectedIkentooMenu);
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };

  render() {
    const { __, restaurants, isChooseMenuModalOpen, ikentooMenusForLocation } = this.props;
    const { error, selectedIkentooMenu, pickTime, selectedRestaurant } = this.state;
    const stores = restaurants || [];
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant) || null;
    const deliveryOption = this.props.deliveryOption || Basket.getDeliveryOption();
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? store.table_json_time_selector : [],
    );
    return (
      <Loading transparent>
        <Layout headerTitle={__(deliveryOption ? deliveryOption.label : '')} color="transparent">
          <div className="flex-row-wrapper absolute-content">
            <div className="scrollable-y">
              <Title className="okx-font-secondary">
                {deliveryOption ? __(deliveryOption.label) : ''}
              </Title>
              <NormalText>
                {__(
                  'Enter your table number and your order will be brought to you as soon as possible. This service is available for in-store use only.',
                )}
              </NormalText>
              <Spacer size={4} />
              <label className="select-picker-label" htmlFor="demo-non-form">
                {__('Location')}
              </label>
              <SelectOption
                display="center"
                onSet={(e, a) => this.selectRestaurant(e, a)}
                data={this.formatDataForSelect(stores)}
                label="Location"
                value={this.state.selectedRestaurant}
                inputStyle="box"
                placeholder={__(restaurantSelectPlaceholder)}
                setText={__('OK')}
                cancelText={__('Cancel')}
                disabled={restaurants.length <= 1 ? true : false}
              />
              <Spacer size={2} />
              <label className="time-picker-label" htmlFor="demo-non-form">
                {__('Table Number')}
              </label>
              {/* <SelectOption
								display="center"
								onSet={ (e, inst) => this.setPickTime(e, inst, minDT) }
								data={ timePickerOptions }
								label="Location"
								value={ pickTime }
								inputStyle="box"
								placeholder={ __('Select Collection Time') }
								setText={ __('OK') }
								cancelText = { __('Cancel') }
								disabled={this.state.selectedRestaurant === null ? true : false}
								onInit={ () => {
									if (timePickerOptions.length > 0) {
										const firstAvailableTime = timePickerOptions.find(i => i.value !== null)
										if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
											this.changeTime(firstAvailableTime.value, minDT)
										}
									}
								}}
							/> */}
              <IonInput
                className="table-number-input"
                onIonChange={(e) => this.setState({ tableNumber: e.target.value })}
                value={this.state.tableNumber}
                type="text"
                placeholder={__('Enter table number')}
              />
              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
            </div>
            <div className="flex-min">
              <IonButton
                disabled={this.state.tableNumber ? false : true}
                expand="block"
                color="primary"
                className="uppercase okx-font-secondary"
                onClick={() => this.chooseMenusForLocation()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
          <div
            className="click-collect-pickers-backdrop"
            style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
            onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
          ></div>
          <div className={`click-collect-dialog ${animationMenuClass}`}>
            {/* <Loading transparent> {null} </Loading> */}
            <div className="click-collect-dialog-layout sc-ion-modal-md">
              <div className="click-collect-dialog-header">
                <Title>{__('Choose menu')}</Title>
              </div>
              <div
                className="click-collect-dialog-closer"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
              >
                <ion-icon
                  name="close"
                  role="img"
                  className="md hydrated"
                  aria-label="close"
                ></ion-icon>
              </div>
              <div className="click-collect-dialog-content">
                <IonList lines="none">
                  <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                    {menus.length === 0 ? (
                      <NoData />
                    ) : (
                      menus.map((menu) => {
                        const { ikentooMenuId, menuName } = menu;
                        return (
                          <IonItem key={ikentooMenuId} lines="full">
                            <div tabIndex="-1"></div>
                            <IonLabel className="ion-text-wrap">
                              <NormalText>{menuName}</NormalText>
                            </IonLabel>
                            <IonRadio color="primary" slot="start" value={ikentooMenuId} />
                          </IonItem>
                        );
                      })
                    )}
                  </IonRadioGroup>
                </IonList>
              </div>
              <div className="click-collect-dialog-action">
                {error ? (
                  <IonItem>
                    <div tabIndex="-1"></div>
                    <FieldError className="field-error" value={__(error)} />
                  </IonItem>
                ) : null}
                <IonButton
                  disabled={pickTime && menus.length > 0 ? false : true}
                  expand="block"
                  color="primary"
                  className="customized-button uppercase"
                  onClick={() => this.continueOnMenu()}
                >
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption } = state.orders;
  const { restaurants, ikentooMenusForLocation } = state.restaurants;
  const { isChooseMenuModalOpen } = state.common;
  return {
    deliveryOption,
    restaurants: restaurants || [],
    profile: state.profile.profile,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    ikentooMenusForLocation: ikentooMenusForLocation || [],
  };
};

export default connect(stateToProps)(withTranslation(OrderToTable));
