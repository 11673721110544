import React, { Component } from 'react';
import { IonGrid, IonRow, IonCol, IonButton } from '@ionic/react';
import { SmallText } from '../common';
import { makeKey, isDefined, forwardTo, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import './index.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ServiceCharge } from '../../screens/service-charge';
import Modal from '../modal';
import { getConfig } from '../../appConfig';

class OrderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceChargeModalOpen: false,
      showCharge: false,
    };
  }

  handleServiceChargeModal = (flag) => {
    if (isWebConfig()) {
      this.setState({ serviceChargeModalOpen: flag, showCharge: !flag });
    } else {
      forwardTo('/service-charge');
    }
  };

  drawSubItems = (basketItem = {} /*, basketItemIndex*/) => {
    const { selectedChoices } = basketItem;
    const basketInstance = this.props.basketInstance || Basket;
    const { profile } = this.props;

    if (selectedChoices && selectedChoices.length > 0) {
      return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
        return (choiceGroup || []).map((choice, choiceIndex) => {
          const { sku } = choice;
          return (
            <IonRow
              key={makeKey(
                choiceGroupIndex,
                choiceIndex,
                sku,
              )} /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/
            >
              <IonCol size="50px"></IonCol>
              <IonCol className="paddLR grow">
                {choice.quantity} x {Basket.getProductName(choice, profile)}
              </IonCol>
              <IonCol className="righted paddLR self-aligned">
                {basketInstance.calculateSubItemPrice(choice, choice.quantity) !== 0
                  ? basketInstance._calculateSubItemPrice(choice, choice.quantity)
                  : null}
              </IonCol>
            </IonRow>
          );
        });
      });
    }
    return null;
  };

  render() {
    const { handleOrderItemClick, __, history, profile, showAddItems, totalClass } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const type = this.props.type || 'order';
    const orderType = basketInstance.getOrderType();
    const appliedPoints = basketInstance.getAppliedPoints();
    const deliveryPrice = basketInstance.getDeliveryPrice();
    const deliveryPriceUnformated = basketInstance._getDeliveryPrice();
    const { serviceChargeModalOpen } = this.state;

    return (
      <IonGrid className="order-content-wrapper paddL">
        {basketInstance.getItems().map((basketItem, basketItemIndex) => {
          const { quantity, item, instructions } = basketItem;
          return (
            <div key={basketItemIndex} className="basket-item-wrapper">
              <IonRow
                key={makeKey(basketItemIndex, item.sku)}
                onClick={() => {
                  if (handleOrderItemClick) {
                    handleOrderItemClick(basketItem, basketItemIndex);
                  }
                }}
              >
                <IonCol className="paddLR grow">
                  {quantity} x {Basket.getProductName(item, profile)}
                </IonCol>
                <IonCol className="righted paddLR self-aligned">
                  {basketInstance.calculateItemPriceByIndex(basketItemIndex, false) > 0
                    ? basketInstance._calculateItemPriceByIndex(basketItemIndex, false)
                    : null}
                </IonCol>
              </IonRow>
              {this.drawSubItems(basketItem, basketItemIndex)}
              {instructions && instructions !== '' ? (
                <IonRow>
                  <IonCol>
                    <SmallText>{__('Special Notes')}:</SmallText>
                  </IonCol>
                  <IonCol className="righted instruction-grow">
                    <SmallText>{instructions}</SmallText>
                  </IonCol>
                </IonRow>
              ) : null}
            </div>
          );
        })}
        {showAddItems ? (
          <IonRow className="righted add-items-row">
            <IonButton
              size="small"
              color="black"
              className="rounded add-items-btn"
              onClick={() => forwardTo('/order')}
            >
              + {__('Add Items')}
            </IonButton>
          </IonRow>
        ) : null}

        <>
          {basketInstance.process_fee_value > 0 && (
            <div className="basket-item-wrapper">
              <IonRow className="basket-item-service-charge-row">
                <>
                  <IonCol className="paddLR grow ">{__('Order processing fee')}</IonCol>
                  <IonCol className="righted paddLR self-center ">
                    {basketInstance.getProcessingFee()}
                  </IonCol>
                </>
              </IonRow>
            </div>
          )}
          {(basketInstance.service_percentage > 0 &&
            orderType === 'Table' &&
            getConfig().general.hasServiceCharge) ||
          basketInstance.getServiceChargeValue() > 0 ? (
            <div key="service_charge" className="basket-item-wrapper">
              <IonRow
                className="basket-item-service-charge-row service-charge-item"
                onClick={() => this.handleServiceChargeModal(true)}
              >
                <>
                  <IonCol className="paddLR grow ">
                    {__('Service charge')}&nbsp;{basketInstance._getServicePercentage()}
                  </IonCol>
                  <IonCol className="righted paddLR self-center ">
                    {basketInstance.getServiceChargeValue() > 0
                      ? basketInstance.getFormatedServiceChargeValue()
                      : basketInstance.getServiceCharge(true)}
                  </IonCol>
                </>
              </IonRow>
            </div>
          ) : null}
          {deliveryPriceUnformated > 0 ? (
            <IonRow>
              <IonCol className="paddLR grow">{__('Delivery Charge')}</IonCol>
              <IonCol className="righted paddLR">
                {/* basketInstance._calculatePointsAppliedPrice(null, true) */} {deliveryPrice}
              </IonCol>
            </IonRow>
          ) : null}
          {isDefined(appliedPoints) && appliedPoints > 0 ? (
            <IonRow className="subtotal-bordered-top">
              <IonCol className="paddLR grow okx-font-secondary">{__('Subtotal')}</IonCol>
              <IonCol className="righted paddLR okx-font-secondary">
                {basketInstance._getSubTotal()}
              </IonCol>
            </IonRow>
          ) : null}
          {isDefined(appliedPoints) && appliedPoints > 0 ? (
            <IonRow
              onClick={() => {
                if (type === 'order' && history) {
                  history.push('/apply-points');
                }
              }}
            >
              <IonCol className="paddLR grow">
                {appliedPoints}&nbsp;{__('Points Redeem')}
              </IonCol>
              <IonCol className="righted paddLR">
                {basketInstance._calculatePointsAppliedPrice(null, true)}
              </IonCol>
            </IonRow>
          ) : null}
        </>

        <IonRow className="total-bordered-top">
          <IonCol className="paddLR">
            <IonCol className="paddLR grow okx-font-secondary">{__('Total')}</IonCol>
          </IonCol>
          <IonCol className="righted paddLR okx-font-secondary">
            {basketInstance._getTotal(true)}
          </IonCol>
        </IonRow>
        {basketInstance.service_percentage === 0 &&
        orderType === 'Table' &&
        getConfig().general.hasServiceCharge &&
        basketInstance.getServiceChargeValue() == null ? (
          <div key="service_charge" className="basket-item-wrapper">
            <IonButton
              expand="block"
              fill="clear"
              color="primary"
              className="link underlined basket-item-service-charge-row"
              onClick={() => this.handleServiceChargeModal(true)}
            >
              add service charge
            </IonButton>
          </div>
        ) : null}

        <Modal
          cssClass="service-charge-modal"
          isOpen={serviceChargeModalOpen}
          onDidDismiss={() => this.handleServiceChargeModal(false)}
        >
          <ServiceCharge
            handleServiceChargeModal={this.handleServiceChargeModal}
            serviceChargeModalOpen={serviceChargeModalOpen}
          />
        </Modal>
      </IonGrid>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    profile: store.profile.profile,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)));
