import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject, forwardTo, getSingleDeliveryOption } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { NormalText, Spacer, StrongText, Title } from '../../components/common';
import HistoryOrderBar from '../../components/historyOrderBar';

const { hasOrdering } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      singleDelivery: null,
      image: '',
    };
  }

  componentDidMount() {
    const { clientProfile } = this.props;
    getSingleDeliveryOption().then((res) => {
      this.setState({
        singleDelivery: res,
        image: clientProfile.main_image,
      });
    });
  }

  render() {
    const { __, screenName, orderHistory } = this.props;
    const { singleDelivery, image } = this.state;
    return (
      <>
        <Layout color="transparent" noPadding={true}>
          <div
            className="absolute-content dash-layout"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
          <IonCard className="dash-card">
            <IonCardHeader className="ion-text-center">
              <StrongText>{__('Welcome Back')}</StrongText>
              <Title>{screenName}</Title>
            </IonCardHeader>
            <Spacer size={4} />
            <IonCardContent className="dash-menu">
              <IonList>
                {hasOrdering ? (
                  singleDelivery ? (
                    <IonItem className="clickable" onClick={() => forwardTo('/click-and-collect')}>
                      <div tabIndex="-1"></div>
                      <IonLabel>{__(singleDelivery?.label)}</IonLabel>
                    </IonItem>
                  ) : (
                    <IonItem className="clickable" onClick={() => forwardTo('/delivery-options')}>
                      <div tabIndex="-1"></div>
                      <IonLabel>{__('Start New Order')}</IonLabel>
                    </IonItem>
                  )
                ) : null}
                <IonItem className="clickable" onClick={() => forwardTo('/loyalty')}>
                  <div tabIndex="-1"></div>
                  <IonLabel>{__('Loyalty')}</IonLabel>
                </IonItem>
                <IonItem lines="none" className="clickable" onClick={() => forwardTo('/account')}>
                  <div tabIndex="-1"></div>
                  <IonLabel>{__('My Account')}</IonLabel>
                </IonItem>
              </IonList>
            </IonCardContent>
          </IonCard>
        </Layout>
        <div className="info-panel">
          {orderHistory.length > 0 && (
            <HistoryOrderBar
              label={__('Order again')}
              handleClick={() => forwardTo('./history', { tab: 'order' })}
            />
          )}
        </div>
      </>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { clientProfile } = state.common;
  const { orderHistory } = state.orders;

  let screenName = '';
  if (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) {
    screenName = profile.first_name;
  }
  return {
    screenName,
    clientProfile,
    orderHistory,
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
