import React from 'react';
import { StrongText } from '../common';
import './index.css';

const HistoryOrderBar = ({ label, onDismiss, handleClick }) => (
  <>
    <div className="history-order-bar-scrim" onClick={onDismiss ? onDismiss : null}></div>
    <div className="history-order-bar" onClick={handleClick}>
      <StrongText className="capitalized">{label}</StrongText>
    </div>
  </>
);

export default HistoryOrderBar;
