import React from 'react';
import { IonItem, IonButton, IonGrid, IonRow, IonCol, IonAlert } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, Spacer, NormalText } from '../../components/common';
import Box, { BoxHeader } from '../../components/box';
import { connect } from 'react-redux';
import { createNewBasketInstance } from '../../lib/basket';
import { forwardTo, visaEndingDrawText } from '../../lib/utils';
import OrderContent from '../../components/orderContent';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withRouter } from 'react-router';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import AmountPaid from '../../components/amountPaid';
import './index.css';
import { logout } from '../../store/actions';

class OrderCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastOrder) {
      if (prevProps.lastOrder === null || prevProps.lastOrder.id !== this.props.lastOrder.id) {
        const { lastOrder } = this.props;
        const orderArr = lastOrder;
        const now = moment();
        let cutoffTime = orderArr ? orderArr.cutoff_time : now;
        let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
        const utcOffset = cutoffTimeMoment.utcOffset();
        cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
        if (now.isBefore(cutoffTimeMoment)) {
          this.setState({
            isCancel: true,
            orderId: orderArr.id,
            restaurantId: orderArr.restaurant_id,
          });
        }
      }
    }
  }

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <Title>
          {__('Drop Point')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Delivery') {
      return (
        <Title>
          <strong>
            {__('Delivery')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else if (orderType === 'Table') {
      return (
        <Title>
          <strong>
            {__('At Table')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else {
      return (
        <Title>
          {__(orderType)} #{id}
        </Title>
      );
    }
  };

  drawContent = (__, order, orderType, basketInstance, orderCompletePage) => {
    if (orderType === 'Click & Collect') {
      return (
        <>
          <IonRow>
            <IonCol size="4" className="lefted self-aligned">
              <NormalText tag="strong">{__('Collection From')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{order.restaurant_name}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4" className="lefted self-aligned">
              <NormalText tag="strong">{__('Order Placed')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4" className="lefted self-aligned">
              <NormalText tag="strong">{__('Collection Time')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{basketInstance.formatOrderTime(true)}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4" className="lefted self-aligned">
              <NormalText tag="strong">{__('Payment Method')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>
                {visaEndingDrawText(
                  basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                )}
              </NormalText>
            </IonCol>
          </IonRow>
        </>
      );
    } else if (orderType === 'Delivery') {
      return (
        <>
          <IonRow>
            <IonCol size="5">
              <NormalText tag="strong">{__('Delivery to')}:</NormalText>
            </IonCol>
            <IonCol>
              <NormalText>
                {order.delivery_address.addressLine1}, {order.delivery_address.place},{' '}
                {order.delivery_address.postalCode}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <NormalText tag="strong">{__('Order Placed')}:</NormalText>
            </IonCol>
            <IonCol>
              <NormalText>{moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <NormalText tag="strong">{__('Delivery Time')}:</NormalText>
            </IonCol>
            <IonCol>
              <NormalText>{basketInstance.formatOrderTime(true)}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <NormalText tag="strong">{__('Payment Method')}:</NormalText>
            </IonCol>
            <IonCol>
              <NormalText>
                {visaEndingDrawText(
                  basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                )}
              </NormalText>
            </IonCol>
          </IonRow>
        </>
      );
    } else if (orderType === 'Outpost Drop-Off') {
      return (
        <>
          <IonRow>
            <IonCol size="5">
              <NormalText tag="strong">{__('Drop-off at')}:</NormalText>
            </IonCol>
            <IonCol>
              <NormalText>{order.restaurant_name}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <NormalText tag="strong">{__('Order Placed')}:</NormalText>
            </IonCol>
            <IonCol>
              <NormalText>{moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <NormalText tag="strong">{__('Drop-off Time')}:</NormalText>
            </IonCol>
            <IonCol>
              <NormalText>{basketInstance.formatOrderTime(true)}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5">
              <NormalText tag="strong">{__('Payment Method')}:</NormalText>
            </IonCol>
            <IonCol>
              <NormalText>
                {visaEndingDrawText(
                  basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                )}
              </NormalText>
            </IonCol>
          </IonRow>
        </>
      );
    } else if (orderType === 'Table') {
      return (
        <>
          <IonRow>
            <IonCol size="5" className="lefted self-aligned">
              <NormalText tag="strong">{__('Ordered at')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{order.restaurant_name}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="lefted self-aligned">
              <NormalText tag="strong">{__('Table number')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{order.table_name}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="lefted self-aligned">
              <NormalText tag="strong">{__('Order Placed')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="lefted self-aligned">
              <NormalText tag="strong">{__('Payment Method')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>
                {visaEndingDrawText(
                  basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage),
                )}
              </NormalText>
            </IonCol>
          </IonRow>
        </>
      );
    }
  };

  drawOrder = (order, orderCompletePage) => {
    const { __, lastOrder, cards } = this.props;
    const { id } = order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();

    return (
      <>
        <div className="order-status">
          {lastOrder && lastOrder.status ? __(lastOrder.status) : ''}
        </div>
        {this.drawContentTitle(__, Basket.getDeliveryOption(), id, basketInstance.getOrderType())}
        <Spacer size={2} />
        <Box>
          <BoxHeader>
            <br />
            <NormalText className="medium-font-size">
              <strong>{__('Order Details')}</strong>
            </NormalText>
            <IonGrid className="okx-box-header-grid">
              {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
            </IonGrid>
          </BoxHeader>
          <br />
          <NormalText className="medium-font-size">
            <strong>{__('Items Ordered')}</strong>
          </NormalText>
          <OrderContent
            basketInstance={basketInstance}
            type="orderHistory"
            totalClass="uppercase"
            totalBorderedBottom={true}
          />
          <AmountPaid order={order} cards={cards} />
        </Box>
        {orderType !== 'Click & Collect' ? (
          this.state.isCancel ? (
            <IonButton
              expand="block"
              fill="clear"
              className="link underlined"
              color="dark"
              onClick={() => this.handleCancelOrderModal(true)}
            >
              {__('Cancel this order')}
            </IonButton>
          ) : null
        ) : null}
        {this.props.profile.is_guest && (
          <IonButton
            className="uppercase"
            expand="block"
            onClick={() => {
              this.props.dispatch(logout());
            }}
          >
            {__('Done')}
          </IonButton>
        )}
      </>
    );
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  noOrder = () => (
    <IonItem lines="none">
      <div className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
        {this.props.__('No order')}
      </div>
    </IonItem>
  );

  backHandler = () => {
    const { location } = this.props;
    if (location && location.state && location.state.completedOrder) {
      forwardTo('/dashboard');
      forwardTo('/click-and-collect');
      Basket.reset();
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  render() {
    const { __, lastOrder } = this.props;
    const order = this.props.order || lastOrder;
    const orderCompletePage = true;

    return (
      <Layout
        color="transparent"
        headerTitle={__('Order Completed')}
        backHandler={this.backHandler}
      >
        <div className="absolute-content order-details">
          <div className="scrollable-y">
            {order ? this.drawOrder(order, orderCompletePage) : this.noOrder()}
          </div>
        </div>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  const { profile } = store.profile;
  return {
    lastOrder,
    cards: orders.cards || [],
    restaurants: restaurants || [],
    cancelOrderModal,
    profile,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderCompleted)));
