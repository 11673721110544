import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Plugins, Capacitor } from '@capacitor/core';
import Basket from '../../lib/basket';

import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonMenuToggle,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
  IonFooter,
  IonAlert,
  IonItemGroup,
  IonAvatar,
} from '@ionic/react';
import { logout, setDeliveryOption } from '../../store/actions';
import { getDefaultRoute, forwardTo, padNumber, isWebConfig, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { Spacer, Hr, Subtitle, SmallText, NormalText, StrongText } from '../../components/common';
import { getConfig } from '../../appConfig';
import { version as packageJsonVersion } from '../../../package.json';
import SmallDrawer from './smallDrawer';
import { chevronBack, chevronDown, chevronUp } from 'ionicons/icons';
import defaultImg from '../../assets/images/gray-avatar.png';
import './index.css';
const collectionIcon = require('../../assets/images/collection.svg');
const deliveryIcon = require('../../assets/images/delivery.svg');
const tableIcon = require('../../assets/images/table.svg');
const logo5Loyalty = require('../../assets/images/5loyalty-black.svg');
const openExternalLink = (url) => window.open(url, '_system', 'location=yes');

const qm = require('../../assets/images/qm.svg');

const { Device } = Plugins;
const NavItem = withRouter(({ history, item, hideIcon, handleLogout, className, __ }) => {
  const selected = history.location.pathname === item.path;
  return (
    <IonMenuToggle key={item.path} autoHide="false">
      <IonItem
        className={
          'nav-item' + (selected ? ' okx-nav-selected' : '') + (className ? ' ' + className : '')
        }
        onClick={() =>
          item.fn === 'logout'
            ? handleLogout()
            : item.isLink
            ? openExternalLink(item.path)
            : forwardTo(item.path, item.state)
        }
      >
        <div tabIndex="-1"></div>
        {hideIcon ? null : (
          <IonIcon className="nav-icon" slot="start" icon={item.icon ? item.icon : qm} />
        )}
        <NormalText className="nav-label">{__(item.label)}</NormalText>
      </IonItem>
    </IonMenuToggle>
  );
});

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appDeviceVersion: '',
      showPopup: false,
      menuVisible: true,
      logBoxOpen: false,
      profile_image_url: this.props.profile.profile_image_url || defaultImg,
      logo_image: this.props.clientProfile.logo_image || '',
      isLogoClicked: false,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handleLogout() {
    this.props.dispatch(logout());
    const defaultRoute = getDefaultRoute(this.props.navConfig);
    forwardTo(defaultRoute.path);
  }

  handleModal(val) {
    this.setState({ showPopup: val });
  }

  async componentDidMount() {
    const info = await Device.getInfo();
    const appDeviceVersion = info.appVersion;
    this.setState({ appDeviceVersion: appDeviceVersion }, () => {
      this.checkVerison();
    });
    if (
      this.props.location.pathname == '/order' &&
      isWebConfig() &&
      this.state.menuVisible == true
    ) {
      this.toggleMenu();
    }
  }

  addZeros = (arr = []) =>
    arr.map((i, index) => {
      // e.g. 1.23.8
      // skip first number (app version) (e.g. 1)
      // add zeros only to patch (e.g. 23) or minor (e.g. 8)
      if (index !== 0) {
        return padNumber(i, 3);
      }
      return i;
    });

  isAppVersionValid = (apiVersion = '', appVersion = '') => {
    let ret = true;
    if (apiVersion && appVersion && apiVersion !== '' && appVersion !== '') {
      const apiVersionInt = parseInt(this.addZeros(apiVersion.split('.')).join(''), 10);
      const appVersionInt = parseInt(this.addZeros(appVersion.split('.')).join(''), 10);
      ret = appVersionInt >= apiVersionInt;
      // eslint-disable-next-line no-console
      console.log(
        'APP VERSION:' +
          '\n    isValid:    ' +
          ret +
          '\n    platform:   ' +
          (Capacitor.platform !== 'web' ? 'MOBILE' : 'WEB') +
          '\n    device:     (' +
          typeof appVersion +
          ')-> ' +
          appVersion +
          ' (int: ' +
          appVersionInt +
          ')' +
          '\n    apiversion: (' +
          typeof apiVersion +
          ')-> ' +
          apiVersion +
          ' (int: ' +
          apiVersionInt +
          ')',
      );
    } else {
      // eslint-disable-next-line no-console
      console.error('Skip version checking.');
    }
    return ret;
  };

  checkVerison = () => {
    const { appDeviceVersion } = this.state;
    if (Capacitor.platform !== 'web') {
      if (
        !this.isAppVersionValid(this.props.appVersion, appDeviceVersion) &&
        appDeviceVersion !== ''
      ) {
        this.handleModal(true);
      }
    } else {
      // web version checking
      if (!this.isAppVersionValid(this.props.appVersion, packageJsonVersion)) {
        this.handleModal(true);
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.profile.is_guest) {
      if (
        this.props.location.pathname !== '/order-completed' &&
        this.props.location.pathname !== '/checkout' &&
        this.props.location.pathname !== '/card-add' &&
        this.props.location.pathname !== '/create-account'
      ) {
        Basket.reset();
        this.props.dispatch(logout());
      }
    }

    if (this.props.appVersion !== prevProps.appVersion) {
      this.checkVerison();
    }
    if (prevProps.location !== this.props.location) {
      if (
        this.props.location.pathname == '/order' &&
        !isWebConfig() &&
        this.state.menuVisible == true
      ) {
        this.toggleMenu();
      }
    }
    if (this.props.profile.profile_image_url !== prevProps.profile.profile_image_url) {
      if (this.props.profile.profile_image_url) {
        this.setState({ profile_image_url: this.props.profile.profile_image_url });
      } else {
        this.setState({ profile_image_url: defaultImg });
      }
    }
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible }, () => {
      let drawer = this.state.menuVisible
        ? '--okx-drawer-max-width'
        : '--okx-small-drawer-max-width';
      document.documentElement.style.setProperty('--okx-drawer-width', `var(${drawer})`);
    });
  };

  toggleLogBox = () => {
    const { auth } = this.props;
    const { loggedIn } = auth;
    if (loggedIn) {
      this.setState({ logBoxOpen: !this.state.logBoxOpen });
    } else {
      forwardTo('/login');
    }
  };

  handleNavLogoClick = (path) => {
    forwardTo(path, { isLogoClicked: true });
  };
  setDeliveryOption = (option) => {
    this.props.dispatch(setDeliveryOption(option));
    Basket.setDeliveryOption(option);
    Basket.setOrderType(option.id);
    forwardTo(option.route);
  };

  getDeliveryOptionsIcon = (option) => {
    let icon;
    switch (option.id) {
      case 'charter-delivery':
        icon = deliveryIcon;
        break;
      case 'table':
        icon = tableIcon;
        break;
      default:
        icon = collectionIcon;
    }
    return icon;
  };
  isItemActive = (id) => {
    let active = false;
    switch (id) {
      case 'charter-delivery':
        if (
          this.props.history.location.pathname == '/delivery' ||
          this.props.history.location.pathname == '/delivery-address-add' ||
          this.props.history.location.pathname == '/delivery-address-check' ||
          this.props.history.location.pathname == '/delivery-time'
        ) {
          active = true;
        }
        break;
      case 'table':
        if (this.props.history.location.pathname == '/order-to-table') {
          active = true;
        }
        break;
      case 'collection':
        if (this.props.history.location.pathname == '/click-and-collect') {
          active = true;
        }
    }
    return active;
  };
  render() {
    const { auth, __, profile, navConfig, clientProfile } = this.props;
    const {
      showPopup,
      appDeviceVersion,
      menuVisible,
      logBoxOpen,
      profile_image_url,
      logo_image,
    } = this.state;
    const { loggedIn } = auth;
    const additionalLinks = getConfig().additional_links;
    const defaultRoute = getDefaultRoute(navConfig);
    const groupTitle = [
      { id: 1, title: 'Loyalty and Reward' },
      { id: 2, title: 'Account' },
      { id: 3, title: clientProfile.buisiness_name },
    ];

    const routes = navConfig.routes.filter((route) => !!route.path && !route.notInDrawer);
    return (
      <IonMenu
        style={this.props.profile.is_guest ? { display: 'none' } : {}}
        className="drawer-menu"
        side="start"
        type="overlay"
        contentId="main"
      >
        {menuVisible ? (
          <>
            <IonHeader>
              <IonToolbar>
                {/*<IonTitle>{ getConfig().theme.nav.label }</IonTitle>*/}
                <div
                  className="nav-logo"
                  style={{ backgroundImage: `url(${logo_image})` }}
                  onClick={() => this.handleNavLogoClick(defaultRoute?.path)}
                ></div>
                <IonIcon
                  icon={chevronBack}
                  className="collapse-drawer-icon"
                  onClick={() => this.toggleMenu()}
                />
                <IonButtons slot="end">
                  <IonMenuToggle>
                    <IonButton button clear>
                      <IonIcon slot="icon-only" icon="close" />
                    </IonButton>
                  </IonMenuToggle>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonMenuToggle autoHide="false">
                <IonButton
                  onClick={() => forwardTo('/order')}
                  className="uppercase drawer-menu-order-button"
                >
                  {__('View menu')}
                </IonButton>
              </IonMenuToggle>
              <IonList lines="none">
                <StrongText className="nav-title">{__('Order')}</StrongText>
                {getConfig().delivery.map((d, index) => (
                  <>
                    {!d.isRemoved && (
                      <IonMenuToggle key={index} autoHide="false">
                        <IonItem
                          disabled={d.isDisabled}
                          className={
                            'nav-item' + (this.isItemActive(d.id) ? ' okx-nav-selected' : '')
                          }
                          onClick={() => this.setDeliveryOption(d)}
                        >
                          <IonIcon
                            className="nav-icon"
                            slot="start"
                            icon={this.getDeliveryOptionsIcon(d)}
                          />
                          <NormalText className="nav-label">{__(d.label)}</NormalText>
                        </IonItem>
                      </IonMenuToggle>
                    )}
                  </>
                ))}
                {groupTitle.map((group) => (
                  <>
                    <StrongText className="nav-title">{__(group.title)}</StrongText>
                    {routes.map((route) => {
                      if (route.group == group.id) {
                        return <NavItem __={__} key={route.path} item={route} />;
                      }
                    })}
                  </>
                ))}
                <a
                  className="fiveloyalty-logo-wrapper"
                  href="https://www.5loyalty.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SmallText color="dark">{__('powered by')}</SmallText>
                  <img src={logo5Loyalty} />
                </a>
              </IonList>
            </IonContent>
            <IonFooter className="small-text">
              <IonLabel size="small" slot="start" color="white">
                v
                {Capacitor.platform !== 'web' && appDeviceVersion !== ''
                  ? appDeviceVersion
                  : packageJsonVersion}
              </IonLabel>
              <NavItem
                className="footer-link"
                __={__}
                handleLogout={this.handleLogout}
                item={navConfig.authRoutes.find(
                  (item) => item.fn === (loggedIn ? 'logout' : 'login'),
                )}
              />
            </IonFooter>
          </>
        ) : (
          <SmallDrawer toggleMenu={this.toggleMenu} />
        )}
        <IonAlert
          isOpen={showPopup}
          onDidDismiss={() => this.handleModal(false)}
          header={__('App version')}
          message={__('Your app is out of date. Please update.')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.handleModal(false),
            },
          ]}
        />
      </IonMenu>
    );
  }
}

const stateToProps = (state) => {
  const { auth, profile } = state.profile;
  const { appVersion, clientProfile, clientProfileUpdated, navConfig } = state.common;
  return {
    auth,
    appVersion,
    profile,
    clientProfile,
    clientProfileUpdated,
    navConfig,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Drawer)));
