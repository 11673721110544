import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { getSentGiftVouchers, getTransactionHistory } from '../../store/actions';
import SwipableTabs from '../../components/swipeableTabs';
import { getConfig } from '../../appConfig';
import './index.css';
import { BigLabel, NormalText, Title } from '../../components/common';
import { IonButton, IonInput } from '@ionic/react';
import SendGiftVoucher from './sendGiftVoucher';
import RedeemGiftVoucher from './redeemGiftVoucher';

import Basket from '../../lib/basket';
import { forwardTo, goBack, isWebConfig } from '../../lib/utils';

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultTab: 0,
      checkoutPage: false,
      summaryPage: false,
      detailsPage: true,
    };
  }
  checkoutPageHandler = () => {
    this.setState({ checkoutPage: true, summaryPage: false, detailsPage: false });
  };
  summaryPageHandler = () => {
    this.setState({ checkoutPage: false, summaryPage: true, detailsPage: false });
  };
  detailsPageHandler = () => {
    this.setState({ checkoutPage: false, summaryPage: false, detailsPage: true });
  };
  componentDidMount() {
    Basket.reset();
    const { dispatch } = this.props;
    if (this?.props?.location?.state?.type) {
      this.checkoutPageHandler();
      forwardTo('/gift-vouchers');
    }
    dispatch(getSentGiftVouchers());
  }
  backHandler = () => {
    if (this.state.checkoutPage) {
      this.setState({ checkoutPage: false, summaryPage: true, detailsPage: false });
    }
    if (this.state.summaryPage) {
      this.setState({ checkoutPage: false, summaryPage: false, detailsPage: true });
    }
    if (this.state.detailsPage) {
      Basket.reset();
      forwardTo('/order');
    }
  };

  render() {
    const { __, redeemedGiftVoucher } = this.props;
    if (
      this.props?.location?.state?.giftVoucherSent ||
      this?.props?.location?.state?.type == 'send-voucher'
    ) {
      this.state.defaultTab = 2;
    }
    return (
      <Layout
        headerTitle={__('History')}
        hideSecondToolbar={true}
        color="transparent"
        scrollY={false}
        backHandler={this.backHandler}
      >
        <SwipableTabs
          defaultTab={this.state.defaultTab}
          history={this.props.location}
          tabs={[
            {
              label: __('REDEEM'),
              tabContent: (
                <RedeemGiftVoucher
                  giftVoucherRedeemed={this.props?.location?.state?.giftVoucherRedeemed}
                  redeemedGiftVoucher={redeemedGiftVoucher}
                />
              ),
            },
            {
              label: __('SEND'),
              tabContent: (
                <SendGiftVoucher
                  summaryPageHandler={this.summaryPageHandler}
                  checkoutPageHandler={this.checkoutPageHandler}
                  detailsPageHandler={this.detailsPageHandler}
                  summaryPage={this.state.summaryPage}
                  checkoutPage={this.state.checkoutPage}
                  detailsPage={this.state.detailsPage}
                  sentGiftVouchers={this.props.sentGiftVouchers}
                  giftVoucherSent={this.props?.location?.state?.giftVoucherSent}
                  giftVoucherRedeemed={this.props?.location?.state?.giftVoucherRedeemed}
                />
              ),
            },
          ]}
        />
        )
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  return {
    sentGiftVouchers: state.orders.sentGiftVouchers || [],
    redeemedGiftVoucher: state.orders.redeemedGiftVoucher || null,
  };
};

export default withRouter(withTranslation(connect(stateToProps)(History)));
