import React from 'react';
import QRCode from 'qrcode-react';
import ValidateButton from '../../components/validateButton';
import { withTranslation } from '../../lib/translate';
import { isDefined, validateProfileData, sprintf } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { SmallText, Spacer, NormalText, StrongText, Title } from '../../components/common';

const Scan = ({ __, qr_code, profile, available_balance }) => {
  const valid = validateProfileData(profile).isValid;
  const points_value_percentage =
    isDefined(getConfig().api_config) && isDefined(getConfig().api_config.points_value_percentage)
      ? getConfig().api_config.points_value_percentage
      : 5;
  return (
    <>
      <div className="loyalty-content scan padded scrollable-y">
        <div>
          <NormalText>{__('Loyalty Wallet')}</NormalText>
          <Title className="signature okx-font-secondary margined-top">
            {profile.first_name + ' ' + profile.last_name}
          </Title>
        </div>
        {isDefined(qr_code) ? (
          <div className="qr-holder">
            <QRCode value={qr_code} size={150} />
          </div>
        ) : (
          <>
            <Spacer />
            <div className="noQrCode">
              <h5>{__('NO QR CODE')}</h5>
            </div>
          </>
        )}
        <ValidateButton />
        {!valid && getConfig().appType.hasEmailValidationEnabled ? (
          <div className="verified-content">
            <SmallText color="grey">
              {__('You can earn, but not redeem points until your account is verified')}
            </SmallText>
          </div>
        ) : null}
        <Spacer size={3} />
        <NormalText>{__('Loyalty Balance')}</NormalText>
        <Spacer size={1} />
        <StrongText>{sprintf(__('%s Points'), available_balance)}</StrongText>
        <Spacer size={2} />
        <NormalText>
          {__('Earn') + ' '}
          <b>
            {points_value_percentage} {points_value_percentage > 1 ? __('points') : __('point')}
          </b>{' '}
          {__('for every ')} 1 {__('spent')}
        </NormalText>
      </div>
    </>
  );
};

export default withTranslation(Scan);
