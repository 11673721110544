import React from 'react';
import { connect } from 'react-redux';
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonInput,
  IonButton,
  IonSpinner,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
} from '@ionic/react';
import { closeCircle, checkmarkCircle } from 'ionicons/icons';
import Layout from '../../components/layout';
import {
  Title,
  StrongText,
  SmallText,
  Spacer,
  FieldError,
  NormalText,
} from '../../components/common';
import { withTranslation } from '../../lib/translate';
import {
  forwardTo,
  forwardToDeliveryOption,
  sprintf,
  isEmptyObject,
  validateForm,
} from '../../lib/utils';
import {
  setDeliveryAddress,
  postCodeCheck,
  setPostCodeData,
  getNearestLocation,
  setCommonModal,
  storeDeliveryAddress,
  saveDeliveryDataTemporarily,
} from '../../store/actions';
import Basket from '../../lib/basket';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { getConfig } from '../../appConfig';
import '../clickAndCollect/index.css';
import './index.css';
class DeliveryAddressCheck extends React.Component {
  state = {
    form: {
      addressLine1: '',
      addressLine2: '',
      place: '',
      postalCode: '',
      driverNotes: null,
    },
    formErrors: {},
    usePostCode: false,
    initial: true,
    checking: false,
    postalCode: '',
    address: '',
    postalCodeValid: false,
    addressValid: true,
    deliveryZone: [],
    deliveryZoneOption: '',
    deliveryPrice: '',
    restaurant: {},
    validPostCode: false,
    checkMarkFlag: null,
    minOrder: 0,
    searchOptions: {
      componentRestrictions: {
        country: getConfig().general?.defaultState || 'UK',
      },
      types: ['address'],
    },
    haveBackHandler: false,
  };

  formConfig = {
    addressLine1: { required: true },
    addressLine2: { required: false },
    place: { required: true },
  };

  handleInput = (key, val, e) => {
    const form = {
      ...this.state.form,
      [key]: val,
    };

    this.setState({
      form,
      formErrors: validateForm(this.formConfig, form),
    });
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        if (results[0].address_components.length >= 7) {
          this.setState({
            addressValid: true,
            address: results[0].formatted_address,
            form: {
              addressLine1: `${results[0].address_components[0].long_name} ${results[0].address_components[1].long_name}`,
              place: results[0].address_components[2].long_name,
              postalCode:
                results[0].address_components[results[0].address_components.length - 1].long_name,
            },
          });
          this.check(
            results[0].address_components[results[0].address_components.length - 1].long_name,
            this.props.deliveryRangeType,
          );
        } else {
          this.setState({ address: results[0].formatted_address, addressValid: false });
        }
      })
      .catch((error) => console.error('Error', error));
  };

  checkDelivery = () => {
    if (!Basket.getDeliveryOption()) {
      forwardToDeliveryOption();
    }
  };

  componentDidMount() {
    this.checkDelivery();
    const { address_list } = this.props.profile;
    const haveBackHandler = address_list && address_list.length > 0 ? false : this.backHandler;
    this.setState({
      formErrors: validateForm(this.formConfig, this.state.form),
      haveBackHandler: haveBackHandler,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkDelivery();
    if (prevProps.checkedCodeData !== this.props.checkedCodeData) {
      if (
        this.props.checkedCodeData.length > 0 &&
        prevProps.checkedCodeData !== this.props.checkedCodeData
      ) {
        if (this.props.checkedCodeData.length === 1) {
          const minOrder = this.props.checkedCodeData[0].delivery_zone.min_order;
          Basket.setMinOrder(minOrder);
          this.setState({
            minOrder,
            checking: false,
            postalCodeValid: true,
            restaurant: this.props.checkedCodeData[0],
            deliveryPrice: this.props.checkedCodeData[0].delivery_zone.price,
            checkMarkFlag: 'success',
          });
        } else if (this.props.checkedCodeData.length > 1) {
          let deliveryZone = this.props.checkedCodeData;
          this.setState(
            { checking: false, postalCodeValid: true, deliveryZone, checkMarkFlag: 'success' },
            () => {
              this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', true));
            },
          );
        }
      } else if (prevState.checking && this.props.checkedCodeData.length === 0) {
        this.setState({ checking: false, postalCodeValid: false, checkMarkFlag: 'danger' });
      }
    }
  }

  checkPostCode = (value) => {
    // let reg = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/
    // let postalCodeValid = reg.test(value)
    if (value.length >= 3) {
      this.setState({
        postalCodeValid: true,
        postalCode: value,
        initial: false,
        checkMarkFlag: null,
      });
    } else {
      this.setState(
        { postalCodeValid: false, postalCode: value, initial: false, checkMarkFlag: null },
        () => {
          this.props.dispatch(setPostCodeData({ data: [] }));
        },
      );
    }
  };

  setPostalCode = (e) => {
    this.checkPostCode(e.target.value);
  };

  check = (value, type) => {
    const { form, postalCode } = this.state;
    const tempData = {
      addressLine1: form.addressLine1,
      addressLine2: form.addressLine2,
      place: form.place,
      postalCode,
    };

    let charterDelivery = false;
    if (Basket.getDeliveryOption().id === 'charter-delivery') {
      charterDelivery = true;
    }
    if (type === 'postcode') {
      this.props.dispatch(postCodeCheck(value, charterDelivery));
      this.props.dispatch(saveDeliveryDataTemporarily(tempData));
      this.setState({ initial: false, checking: true });
    } else if (type === 'distance' || type === 'polygon') {
      const formattedAddress =
        this.state.address.length > 0
          ? this.state.address
          : `${form.addressLine1}, ${form.addressLine2}, ${form.place}, ${postalCode}`;
      this.props.dispatch(getNearestLocation(formattedAddress, charterDelivery));
      this.props.dispatch(saveDeliveryDataTemporarily(tempData));
      this.setState({ initial: false, checking: true, restaurant: {}, deliveryPrice: '' });
    } else {
      this.setState({ initial: true }, () => {
        this.props.dispatch(setPostCodeData({ data: [] }));
        this.props.dispatch(saveDeliveryDataTemporarily(tempData));
      });
    }
    this.setState({ initial: false, checking: true });
  };

  saveAndContinue = () => {
    const { postalCode, restaurant } = this.state;
    const { restaurants } = this.props;
    const minOrder = restaurant.delivery_zone.min_order;
    this.props.dispatch(setDeliveryAddress({ postalCode: postalCode.toUpperCase() }));
    Basket.setRestaurant(restaurants.find((res) => res.id === restaurant.restaurant_id));
    Basket.setServicePercentage(0);

    Basket.setDeliveryPrice(this.state.deliveryPrice);
    this.props.dispatch(storeDeliveryAddress(this.state.form));
    Basket.setDeliveryAddress({ ...this.state.form, postalCode: postalCode });
    Basket.setMinOrder(minOrder);
    forwardTo('/delivery-address-add');
  };

  saveAddressAndContinue = () => {
    const { restaurants } = this.props;
    const { restaurant } = this.state;
    const minOrder = restaurant.delivery_zone.min_order;
    Basket.setDeliveryAddress(this.state.form);
    Basket.setRestaurant(restaurants.find((res) => res.id === restaurant.restaurant_id));
    Basket.setServicePercentage(0);

    Basket.setDeliveryPrice(this.state.deliveryPrice);
    Basket.setMinOrder(minOrder);
    forwardTo('/delivery-address-add');
  };

  restaurantName = (restaurant) => {
    if (restaurant && restaurant['restaurant_id']) {
      return restaurant.restaurant_name;
    }
    return '';
  };

  restaurantAddress = (restaurant) => {
    if (restaurant && restaurant['restaurant_id']) {
      return restaurant.restaurant_address;
    }
    return '';
  };

  changeDeliveryZone = (event) =>
    this.setState({ deliveryZoneOption: event.detail.value, error: '' });

  setDeliveryPrice = () => {
    let option = this.state.deliveryZoneOption;
    let deliveryPrice = this.props.checkedCodeData[option].delivery_zone.price;
    this.setState({ deliveryPrice, restaurant: this.props.checkedCodeData[option] }, () => {
      this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false));
    });
  };
  backHandler = () => {
    this.props.history.go(-2);
  };

  render() {
    const { __, checkedCodeData, isChooseDeliveryModalOpen, deliveryRangeType } = this.props;
    const {
      initial,
      checking,
      postalCode,
      address,
      postalCodeValid,
      deliveryZone,
      deliveryZoneOption,
      deliveryPrice,
      restaurant,
      checkMarkFlag,
      minOrder,
      searchOptions,
      addressValid,
      form,
      formErrors,
    } = this.state;
    const animationMenuClass = isChooseDeliveryModalOpen ? 'show-up' : '';
    const deliveryOption = Basket.getDeliveryOption();
    let formValid = Object.keys(formErrors).length === 0;
    for (const field in form) {
      if (form[field]) {
        delete formErrors[field];
      }
    }

    return (
      <Layout
        headerTitle={__(deliveryOption ? deliveryOption.label : '')}
        noPadding
        color="transparent"
        backHandler={this.state.haveBackHandler}
      >
        <IonCard color="white" className="restaurant-card scrollable-y">
          <IonCardContent className="flex-row-wrapper ">
            <div className="flex-min">
              <Title>{deliveryOption ? deliveryOption.label : ''}</Title>
              <NormalText>{__('Check if we deliver to you')}</NormalText>
            </div>
            <Spacer size="1" />
            <div>
              {this.state.usePostCode ? (
                <form autoComplete="on">
                  <IonItem>
                    <div className="inline-input">
                      <IonLabel position="stacked">{__('Address 1')}</IonLabel>
                      <IonInput
                        required={true}
                        value={form?.addressLine1}
                        autocomplete="street-address"
                        onIonChange={(e) => this.handleInput('addressLine1', e.target.value, e)}
                        type="text"
                        size="50px"
                        clearInput
                      />
                    </div>
                  </IonItem>
                  {formErrors.addressLine1 ? (
                    <FieldError className="field-error" value={__(formErrors.addressLine1)} />
                  ) : null}
                  <IonItem>
                    <div className="inline-input">
                      <IonLabel position="stacked">{__('Address 2')}</IonLabel>
                      <IonInput
                        value={form?.addressLine2}
                        autocomplete="street-address"
                        onIonChange={(e) => this.handleInput('addressLine2', e.target.value, e)}
                        type="text"
                        size="50px"
                        clearInput
                      />
                    </div>
                  </IonItem>
                  {formErrors.addressLine2 ? (
                    <FieldError className="field-error" value={__(formErrors.addressLine2)} />
                  ) : null}
                  <IonItem>
                    <div className="inline-input">
                      <IonLabel position="stacked">{__('Town')}</IonLabel>{' '}
                      <IonInput
                        required={true}
                        value={form?.place}
                        autocomplete="on"
                        onIonChange={(e) => this.handleInput('place', e.target.value, e)}
                        type="text"
                        size="50px"
                        clearInput
                      />
                    </div>
                  </IonItem>
                  {formErrors.place ? (
                    <FieldError className="field-error" value={__(formErrors.place)} />
                  ) : null}
                  <IonItem>
                    <div className="inline-input">
                      <IonLabel position="stacked">{__('Postcode')}</IonLabel>{' '}
                      <div className="post-code-input">
                        <IonInput
                          className="strong-text"
                          required={true}
                          value={postalCode?.toUpperCase()}
                          autocomplete="postal-code"
                          onIonChange={(e) => this.setPostalCode(e)}
                          type="text"
                          size="50px"
                        />
                        {initial || checking || !checkMarkFlag ? null : (
                          <IonIcon
                            size="small"
                            color={checkMarkFlag}
                            icon={checkMarkFlag === 'success' ? checkmarkCircle : closeCircle}
                          />
                        )}
                      </div>
                    </div>
                  </IonItem>
                </form>
              ) : (
                <PlacesAutocomplete
                  value={address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  searchOptions={searchOptions}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <IonItem style={{ '--min-height': '24px' }}>
                        <IonLabel
                          style={{ marginBottom: this.state.usePostCode ? '' : '30px' }}
                          position="floating"
                        >
                          <NormalText>{__('Full Address (including building number)')}</NormalText>
                        </IonLabel>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className:
                              'location-search-input native-input sc-ion-input-md .sc-ion-label-md-h-custom',
                          })}
                        />
                      </IonItem>

                      <div className="autocomplete-dropdown-container ">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              className="pac-container pac-logo"
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                              key={index}
                            >
                              <div className="pac-item">
                                <span className="pac-icon pac-icon-marker"></span>
                                <span className="pac-item-query">
                                  <span>{suggestion.description}</span>
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
              {this.state.usePostCode ? (
                <IonButton
                  fill="clear"
                  className="link underlined"
                  color="dark"
                  onClick={() => {
                    this.setState({
                      usePostCode: false,
                    });
                  }}
                >
                  {__('Check by address?')}
                </IonButton>
              ) : isEmptyObject(restaurant) || !checkMarkFlag ? (
                <IonButton
                  fill="clear"
                  className="link underlined"
                  color="dark"
                  onClick={() => {
                    this.setState({
                      usePostCode: true,
                    });
                  }}
                >
                  {__('Address not listed?')}
                </IonButton>
              ) : null}
              <Spacer />
              <div className="address-checking-box centered">
                {!addressValid ? (
                  <SmallText color="danger">
                    {sprintf(
                      __('Please write the full address in correct form including number'),
                      'small',
                    )}
                  </SmallText>
                ) : null}
                {initial ? null : checking ? (
                  <>
                    <br />
                    <div>
                      <IonSpinner />
                    </div>
                    <SmallText>{__('Checking nearest locations')}</SmallText>
                  </>
                ) : postalCodeValid && checkedCodeData.length > 0 ? (
                  isEmptyObject(restaurant) || !checkMarkFlag ? null : (
                    <>
                      <SmallText>{__('Your order will be delivered from:')}</SmallText>
                      <br />
                      <SmallText>
                        {this.restaurantName(restaurant)}, {this.restaurantAddress(restaurant)}
                      </SmallText>
                      <br />
                      {deliveryPrice > 0 ? (
                        <SmallText>
                          {sprintf(
                            `${__('A small delivery charge of ')}
                              ${Basket.getCurrency().label}${deliveryPrice}
                              ${__(' will apply.')}`,
                            'small',
                          )}{' '}
                          {sprintf(__('Minimum order ' + Basket.formatPrice(minOrder)), 'small')}{' '}
                        </SmallText>
                      ) : (
                        <SmallText>
                          {sprintf(__('There is no delivery charge from this location'), 'small')}
                        </SmallText>
                      )}
                    </>
                  )
                ) : checkedCodeData.length === 0 && !checkMarkFlag ? null : (
                  <>
                    <Spacer size={1} />
                    <SmallText color="danger">
                      {__("Unfortunately, we don't deliver to you yet")}
                    </SmallText>
                  </>
                )}
              </div>
            </div>
            <div className="flex-min">
              {postalCodeValid && checkedCodeData.length > 0 && checkMarkFlag ? (
                this.state.usePostCode ? (
                  <IonButton
                    disabled={!postalCodeValid || deliveryPrice === '' || !formValid}
                    expand="block"
                    color="primary"
                    onClick={this.saveAndContinue}
                  >
                    {__('Continue')}
                  </IonButton>
                ) : (
                  <IonButton
                    disabled={!postalCodeValid || deliveryPrice === ''}
                    expand="block"
                    color="primary"
                    onClick={this.saveAddressAndContinue}
                  >
                    {__('Save address and Continue')}
                  </IonButton>
                )
              ) : this.state.usePostCode ? (
                <IonButton
                  className="no-margin"
                  disabled={!postalCodeValid || !formValid}
                  expand="block"
                  color="primary"
                  onClick={() => {
                    this.check(postalCode, deliveryRangeType);
                  }}
                >
                  {__('Check Postcode')}
                </IonButton>
              ) : null}
            </div>
          </IonCardContent>
        </IonCard>
        <div
          className="click-collect-pickers-backdrop"
          style={{ display: isChooseDeliveryModalOpen ? '' : 'none' }}
          onClick={() => this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))}
        ></div>
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <Title>{__('Choose delivery')}</Title>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() =>
                this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false))
              }
            >
              <ion-icon name="close" role="img" class="md hydrated" aria-label="close"></ion-icon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="full">
                <IonRadioGroup onIonChange={this.changeDeliveryZone} value={deliveryZoneOption}>
                  {deliveryZone
                    .sort((a, b) => {
                      return a.delivery_zone.price - b.delivery_zone.price;
                    })
                    .map((restaurant, i) => {
                      const { restaurant_name } = restaurant;
                      const price = restaurant.delivery_zone.price;
                      return (
                        <IonItem key={i}>
                          <div tabIndex="-1"></div>
                          <IonRadio color="primary" slot="start" value={i} />
                          <IonLabel className="ion-text-wrap" color="dark">
                            {price > 0
                              ? `${restaurant_name}
                                delivery price -
                                ${Basket.getCurrency().label}${price}`
                              : `${restaurant_name} - ${__('Free Delivery')}`}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              <IonButton
                disabled={deliveryZoneOption === ''}
                expand="block"
                color="primary"
                className="uppercase"
                onClick={() => {
                  this.setDeliveryPrice();
                }}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile, auth } = state.profile;
  const { checkedCodeData } = state.orders;
  const { deliveryRangeType, isChooseDeliveryModalOpen } = state.common;
  const { restaurants } = state.restaurants;
  return {
    profile,
    auth,
    checkedCodeData,
    deliveryRangeType,
    isChooseDeliveryModalOpen,
    restaurants,
  };
};

export default connect(stateToProps)(withTranslation(DeliveryAddressCheck));
