import React from 'react';
import { withRouter } from 'react-router';
import {
  IonContent,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { getConfig } from '../../appConfig';
import Header from '../../components/header';
import { getRouteClassName, isWebConfig } from '../../lib/utils';
import { connect } from 'react-redux';
import './index.css';
import StaticHeader from '../staticHeader';

const defaultHeaderTitle = getConfig().general.clientName;

const Layout = ({
  history,
  children,
  headerTitle,
  showSecondToolbar,
  hideBack,
  color,
  poster,
  blank,
  noPadding,
  contentClassName,
  scrollY,
  backHandler,
  selectCategoryOnScroll,
  navConfig,
  profile,
  clientProfile,
}) => {
  useIonViewWillEnter(() => {
    // eslint-disable-next-line no-console
    console.log('1. WillEnter event fired');
  });
  useIonViewDidEnter(() => {
    // eslint-disable-next-line no-console
    console.log('2. DidEnter event fired');
  });
  useIonViewWillLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('3. WillLeave event fired', a, b, c);
  });
  useIonViewDidLeave((a, b, c) => {
    // eslint-disable-next-line no-console
    console.log('4. DidLeave event fired', a, b, c);
  });
  const routeClassName = getRouteClassName(history.location.pathname, navConfig);
  return (
    <>
      {!profile.is_guest ? (
        <>
          {blank ? null : (
            <>
              {!showSecondToolbar ? (
                <StaticHeader
                  title={headerTitle || defaultHeaderTitle}
                  hideBack={hideBack}
                  backHandler={backHandler}
                />
              ) : (
                <>
                  <StaticHeader
                    title={headerTitle || defaultHeaderTitle}
                    hideBack={hideBack}
                    backHandler={backHandler}
                  />
                  <Header
                    title={headerTitle || defaultHeaderTitle}
                    hideBack={hideBack}
                    backHandler={backHandler}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {!isWebConfig() && (
            <IonToolbar
              color="transparent"
              className="primary-toolbar"
              style={{ backgroundImage: !isWebConfig() ? `url(${clientProfile.logo_image})` : '' }}
            ></IonToolbar>
          )}
        </>
      )}
      <IonContent
        scrollY={scrollY !== undefined ? scrollY : true}
        color={color || 'white'}
        className={routeClassName + (contentClassName ? ' ' + contentClassName : '')}
      >
        {poster ? <div className={'poster ' + poster} /> : null}
        <div
          className={noPadding ? 'no-padding' : 'ion-padding'}
          onScroll={routeClassName === 'route-order' ? selectCategoryOnScroll : null}
        >
          {children}
        </div>
      </IonContent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    navConfig: state.common.navConfig,
    profile: state.profile.profile,
    clientProfile: state.common.clientProfile,
  };
}
export default connect(mapStateToProps)(withRouter(Layout));
