import { getEnv } from './lib/env.js';

let appConfig = {
  theme: {
    nav: {
      label: 'Hybrid Deliverect',
    },
    showHeaderOnAuthRoutes: true,
    showInputIconsOnLogin: false,
    routesWithoutStaticHeader: ['/item-details'],
  },
  services: {
    google_maps: {
      google_api_key: getEnv('GOOGLE_API_KEY'),
    },
    // stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt' //Kole
  },
  api: {
    // baseURL: 'http://192.168.0.26:8000', //preda
    baseURL: getEnv('BASE_URL'),
    // baseURL: 'https://hybrid-master.herokuapp.com',
    wordPressBaseURL: 'https://hybridapp.co.uk',
  },
  general: {
    clientName: 'Hybrid Deliverect',
    appExitRoutes: ['/', '/home', '/dashboard'],
    authRoutes: ['/login', '/register', '/reset-password'],
    isReduxDevToolsOn: true,
    isWebPlatform: false,
    // Minimum time between current time and first avalaible time slot
    minTimeDiff: 5,
    routesWithoutBackButton: ['/dashboard'],
    referAndEarnPoints: 5,
  },
  flags: [],
  appType: {
    hasOrdering: true,
    hasLoyalty: true,
    hasEmailValidationEnabled: true,
  },
  configS3: {
    imageNamePrefix: 'hybrid_direct_profile_image_',
    bucketName: 'hybrid-apps-profile-images',
    region: 'eu-west-1',
    accessKeyId: getEnv('S3_ACCESS_KEY_ID'),
    secretAccessKey: getEnv('S3_SECRET_ACCESS_KEY'),
  },
  firebaseConfig: {
    apiKey: getEnv('FIREBASE_API_KEY'),
    authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
    databaseURL: getEnv('FIREBASE_DATABASE_URL'),
    projectId: getEnv('FIREBASE_PROJECT_ID'),
    storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
    appId: getEnv('FIREBASE_APP_ID'),
    measurementId: getEnv('FIREBASE_MEASUREMENT_ID'),
  },
  payment: 'stripe',
  twoColumnTileMenu: true,
};

export const updateConfig = (newConfig) => (appConfig = newConfig);

export const getConfig = () => appConfig;

export default appConfig;
